import styled from "styled-components";

export const StartBuildingMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
    .SeoServicesTechnologies {
      flex-wrap: wrap;
      gap: 2rem;
    }
  }
`;

export const StartInnerText = styled.div`
  font-size: 52px;
  font-weight: 600;
  line-height: 62px;
  width: 70%;
  text-align: center;
  padding-top: 2rem;

  @media (max-width: 1024px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    width: 80%;
  }

  @media (max-width: 778px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 37px;
    width: 100%;
    padding-top: 0rem;
  }
`;

export const MindsLeftButton = styled.div`
  & button.btn.btn-secondary {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    border-radius: 50px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    svg.iconify.iconify--solar {
      margin-inline-start: 0.75rem;
      margin-left: 1rem !important;
    }
  }
`;
