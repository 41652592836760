import Image from "next/image";
import Link from "next/link";
import Marquee from "react-fast-marquee";
export default function MarqueeLogoCertificate(props) {
  return (
    <Marquee
      gradient
      speed="30"
      loop={0} // 0 means infinite looping
      delay={-0}
      autoFill="true"
      gradientColor="transferent"
      gradientWidth="100px"
      className=" certificate w-full p-t-section flex gap-[20px] items-baseline justify-center  lg:my-5 lg:py-[1.5rem] overflow-hidden"
      direction="left"
    >

      <Link href="https://www.g2.com/products/it-services-india/reviews#reviews" target="_blank" aria-label="G2 Reviews">
        <Image src="/svgs/G2LogoSvg.svg" alt="G2" width="65" height="115" loading="lazy" className="light-logo" />
        <Image src="/svgs/G2LogoSvg-Dark.svg" alt="G2" width="65" height="115" loading="lazy" className="dark-logo" />
      </Link>
      <Link href="https://www.goodfirms.co/company/it-services-india" target="_blank" aria-label="Capterra Reviews">
      <Image src="/svgs/GoodFirmsSvg.svg" alt="goodfirms" width="156" height="107" loading="lazy" className="light-logo" />
      <Image src="/svgs/GoodFirmsSvg-Dark.svg" alt="goodfirms" width="156" height="107" loading="lazy" className="dark-logo" />
      </Link>
      <Link href="https://www.softwareworld.co/service/it-services-india-reviews/" target="_blank" aria-label="softwareworld Reviews">
        <Image src="/svgs/SoftwareWorldLogoSvg.svg" alt="softwareworld" width="46" height="106" loading="lazy" className="light-logo" />
        <Image src="/svgs/SoftwareWorldLogoSvg-Dark.svg" alt="softwareworld" width="46" height="106" loading="lazy" className="dark-logo" />
      </Link>
      <Link href="/" target="_self" aria-label="ISO 2015">
      <Image src="/svgs/ISO90012015Svg.svg" alt="ISO 2015" width="66" height="116" loading="lazy" className="light-logo" />
      <Image src="/svgs/ISO90012015Svg-Dark.svg" alt="ISO 2015" width="66" height="116" loading="lazy" className="dark-logo" />
      </Link>
      <Link href="/" target="_self" aria-label="ISO 2013">
      <Image src="/svgs/ISO270032013Svg.svg" alt="ISO 2013" width="66" height="116" loading="lazy" className="light-logo" />
      <Image src="/svgs/ISO270032013Svg-Dark.svg" alt="ISO 2013" width="66" height="116" loading="lazy" className="dark-logo" />
      </Link>
      <Link href="/" target="_self" aria-label="CMMI certification">
      <Image src="/svgs/CMMIDEV3Svg.svg" alt="CMMI certification" width="101" height="63" loading="lazy" className="light-logo" />
      <Image src="/svgs/CMMIDEV3Svg-Dark.svg" alt="CMMI certification" width="101" height="63" loading="lazy" className="dark-logo" />
      </Link>

    </Marquee>
  );
}
